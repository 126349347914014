/* Fonts Family */
@font-face {
  font-family: "Catamaran-Thin";
  src: local("Catamaran-Thin"),
    url("../Fonts/Catamaran-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Catamaran-ExtraLight";
  src: local("Catamaran-ExtraLight"),
    url("../Fonts/Catamaran-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Catamaran-Light";
  src: local("Catamaran-Light"),
    url("../Fonts/Catamaran-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Catamaran-Regular";
  src: local("Catamaran-Regular"),
    url("../Fonts/Catamaran-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Catamaran-Medium";
  src: local("Catamaran-Medium"),
    url("../Fonts/Catamaran-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Catamaran-SemiBold";
  src: local("Catamaran-SemiBold"),
    url("../Fonts/Catamaran-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Catamaran-Bold";
  src: local("Catamaran-Bold"),
    url("../Fonts/Catamaran-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Catamaran-ExtraBold";
  src: local("Catamaran-ExtraBold"),
    url("../Fonts/Catamaran-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Catamaran-Black";
  src: local("Catamaran-Black"),
    url("../Fonts/Catamaran-Black.ttf") format("truetype");
  font-weight: 900;
}

.disable {
  pointer-events: none;
}

.row {
  width: 100%;
  margin: 0;
}

/*  Auth pages styles */
.auth-bg-img {
  background-image: url(../Images/Auth/background.png);
  background-size: cover;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    top: 12vh;
    left: -4.8vh;
    width: 30vh;
    background-image: url(../Images/Auth/icon-bg.svg);
    height: 20.3vh;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 6vh;
    right: -5vh;
    height: 23vh;
    background-image: url(../Images/Auth/icon-bg-right.svg);
    width: 20vh;
    background-size: contain;
  }
  .auth-page {
    img {
      // max-width: 85%;
      height: 80vh;
      z-index: 9;
      position: relative;
      object-fit: cover;
    }
    .auth-form-box {
      h2 {
        color: var(--white);
        text-transform: uppercase;
        text-shadow: 1px 2px RGB(0 0 0 / 20%);
        margin-bottom: 2vh;
        font-size: 5vh;
        @media only screen and (max-height: 700px) {
          margin-bottom: 1vh;
        }
      }
      .authbox-content {
        form {
          text-align: start;
          position: relative;
          @media only screen and (max-height: 559px) {
            margin-top: 1.3vh;
          }
          .input-group {
            border: 3px solid var(--border-color);
            border-radius: 8px;
            flex-wrap: nowrap;
            margin-bottom: 2.6vh;
            @media only screen and (max-height: 700px) {
              margin-bottom: 2vh;
              border: 1.5px solid var(--border-color);
              border-radius: 4px;
            }
            @media only screen and (max-height: 559px) {
              margin-bottom: 3vh;
            }
            input {
              border: 0;
              border-radius: 4px;
              background-color: var(--blue);
              color: var(--white);
              width: inherit;
              margin-bottom: 0;
              height: 5.2vh;
            }
            .input-group-text {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              background-color: #70f0f380;
              color: var(--white);
              font-size: 1.9vh;
              border: 0;
              font-family: var(--Catamaran-300);
              height: 5.2vh;
              padding: 1vh;
              @media only screen and (max-height: 700px) {
                border-top-right-radius: 2.5px;
                border-bottom-right-radius: 2.5px;
                margin-left: 0 !important;
              }
            }
          }
          .validation-message {
            color: #ffd81a;
            position: absolute;
            margin-top: -22px;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            @media only screen and (max-height: 700px) {
              margin-top: -12px;
              font-size: 11px;
            }
            @media only screen and (max-height: 559px) {
              margin-top: -12px;
              font-size: 8px;
            }
          }
          select {
            background-image: url(../Images/Auth/Polygon.svg);
            background-repeat: no-repeat;
            background-position: 97% center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            @media only screen and (max-height: 559px) {
              background-size: 9px;
            }
          }
          select,
          input {
            border: 3px solid var(--border-color);
            height: 5.5vh;
            border-radius: 8px;
            background-color: var(--blue);
            color: var(--white);
            font-family: var(--Catamaran-500);
            font-size: 2vh;
            line-height: 4vh;
            width: 100%;
            outline: 0;
            padding: 0 10px;
            margin-bottom: 2.6vh;
            @media only screen and (max-height: 700px) {
              margin-bottom: 2vh;
              font-size: 13px;
              border: 1.5px solid var(--border-color);
              border-radius: 4px;
            }
            @media only screen and (max-height: 559px) {
              margin-bottom: 3vh;
              font-size: 9px;
              display: block;
              padding: 0 6px;
              height: 6vh;
            }
          }
          label {
            color: var(--white);
            text-transform: uppercase;
            font-family: var(--Catamaran-600);
            text-shadow: 0 3px 6px #00000029;
            margin-bottom: 0.5vh;
            font-size: 2.2vh;
            @media only screen and (max-height: 559px) {
              display: block;
            }
          }
          button {
            font-family: var(--Catamaran-700);
            font-size: 3.26vh;
            background-image: url(../Images/new-assets/Auth-button.png);
            background-repeat: no-repeat;
            color: var(--default-color);
            width: 25vh;
            height: 8.5vh;
            background-color: transparent;
            border: none;
            margin: 1vh auto;
            background-size: contain;
            display: block;
            text-transform: uppercase;
            border-radius: 100px;
            @media only screen and (max-height: 700px) {
              width: 22vh;
              height: 7.5vh;
              margin: 2.5vh auto;
            }
          }
        }
      }
    }
    .bottom-link {
      text-align: center;
      a {
        color: var(--text-color);
        font-size: 2.6vh;
        text-decoration: underline;
        margin-top: 3vh;
        margin-bottom: 2vh;
        @media only screen and (max-height: 700px) {
          margin-top: 1vh;
        }
      }
    }
  }
}

/* Common Styling */
.primary-bg-border {
  background-color: var(--blue-light-bg);
  border-color: var(--blue-border);
  border-width: 4px;
  border-style: solid;
  border-radius: 20px;
  padding: 3vh 4.3vh;
  @media only screen and (max-height: 559px) {
    border-width: 2px;
    border-radius: 10px;
    padding: 2.5vh;
  }
}

.Toastify__toast-body {
  font-size: 16px;
}

.main-bg {
  background-image: url(../Images/background.png);
  min-height: 100vh;
  max-height: 100vh;
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--blue) inset !important;
  -webkit-text-fill-color: var(--white) !important;
}

.btn-close:focus {
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 1vh;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #2b6ebd80;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #2b6fbdb2;
}
