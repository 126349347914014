:root {
  .introduction-page {
    background-image: url(../../Assets/Images/background.png);
    max-height: 100vh;
    background-size: cover;
    background-position: bottom center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    header {
      h1 {
        color: #304594;
        text-align: center;
        margin-top: 3.3vh;
        margin-bottom: 2.2vh;
        font-family: var(--Catamaran-700);
        text-transform: uppercase;
      }
    }
    .sliderbox {
      background-image: url(../../Assets/Images/categories/slidebox.svg);
      width: 53.4vh;
      background-size: contain;
      height: 43.6vh;
      margin: auto;
      margin-bottom: 2.2vh;
      padding: 3.8vh;
    }
    .video-box {
      position: relative;
      border-radius: 16px;
      background-color: #000000;
      overflow: hidden;
      width: 45.7vh;
      height: 31.6vh;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 45.7vh !important;
        height: 31.6vh !important;
      }
      .cover {
        border-radius: 24px;
      }
      .playbutton {
        position: absolute;
        top: 33%;
        left: 38%;
        cursor: pointer;
        width: 11.6vh;
      }
    }
    .carousel-indicators {
      bottom: -5.5vh;
      margin-bottom: 0;
    }
    .carousel-indicators [data-bs-target] {
      width: 2vh;
      height: 2vh;
      border-radius: 100%;
      margin-right: 1.1vh;
      margin-left: 1.1vh;
      background-color: #304594;
    }
    .content {
      padding: 0 64px;
      max-height: calc(100vh - 38vh);
      min-height: calc(100vh - 38vh);
      overflow-y: scroll;
      p {
        text-align: center;
        font-size: 2.9vh;
      }
    }
    .play-button {
      margin-bottom: 2.2vh;
      margin-top: 2.2vh;
      z-index: 9;
      position: relative;
      .play {
        font-family: var(--Catamaran-700);
        font-size: 4.4vh;
        line-height: 7.4vh;
        background-image: url(../../Assets/Images/new-assets/qa-button.png);
        color: var(--default-color);
        width: 31vh;
        height: 10.3vh;
        background-color: transparent;
        border: none;
        margin: auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        text-transform: uppercase;
        border-radius: 100px;
        padding: 2vh 1.3vh 0.9vh 1.3vh;
      }
    }
  }
}
.modal {
  --bs-modal-width: 50%;
  video {
    width: 100%;
  }
}
