:root {
  .leaderboard-page {
    background-image: url(../../Assets/Images/background.png);
    padding: 0 !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .header {
      border-bottom: 0;
      padding: 1.7vh 7vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 3vh;
      .title {
        h2 {
          font-family: var(--Catamaran-700);
          font-size: 5.3vh;
          color: var(--default-color);
          margin-top: 1.7vh;
          text-transform: uppercase;
        }
      }
      img {
        cursor: pointer;
        width: 10.2vh;
        border-radius: 100%;
        object-fit: contain;
      }
    }
    .page-body {
      padding: 1.7vh 7vh;
      min-height: calc(100% - 26.4vh);
      max-height: calc(100% - 26.4vh);
      overflow-y: auto;
      position: relative;

      .user-data {
        display: flex;
        justify-content: space-between;
        font-size: 30px;
        color: var(--text-color);
      }
      .title-blue {
        color: var(--default-color);
        font-family: var(--Catamaran-700);
        font-size: 30px;
      }
      form {
        text-align: start;
        .form-row {
          margin-bottom: 1.7vh;
        }
        select {
          border: 4px solid var(--border-color);
          height: 6vh;
          border-radius: 8px;
          background-color: var(--blue);
          color: var(--white);
          font-family: var(--Catamaran-600);
          font-size: 2.4vh;
          line-height: 4.1vh;
          background-image: url(../../Assets/Images/icons/dropdown-arrow.svg);
          background-size: 1.7vh;
          padding: 0.7vh 1.7vh;
          outline: none !important;
          box-shadow: none !important;
          @media (max-height: 559px) {
            border: 2px solid var(--border-color);
            border-radius: 4px;
          }
          option {
            font-family: var(--Catamaran-400);
          }
        }
        label {
          color: var(--text-color);
          text-transform: uppercase;
          font-family: var(--Catamaran-600);
          text-shadow: 0px 3px 6px #00000029;
          margin-bottom: 4px;
          @media (max-height: 559px) {
            font-size: 3.5vh;
            margin-bottom: 0;
          }
        }
        button {
          font-family: var(--Catamaran-700);
          font-size: 35px;
          background-image: url(../../Assets/Images/Auth/button.png);
          color: var(--default-color);
          width: 250px;
          height: 96px;
          background-color: transparent;
          border: none;
          margin-left: auto;
          background-size: contain;
          display: block;
          text-transform: uppercase;
        }
      }
    }
    .footer {
      padding: 0 !important;
      border-top: 0;
      position: relative;
    }
    .pagination {
      position: absolute;
      align-items: center;
      top: 3.3vh;
      left: 7vh;
      li {
        &.muted {
          .page-link {
            background-color: transparent;
            font-size: 3.7vh;
            margin-top: -0.6vh;
            cursor: default;
          }
        }
        .page-link {
          border-radius: 50px;
          margin: 0.2vh;
          width: 4.4vh;
          height: 4.4vh;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          padding: 0;
          font-size: 2.2vh;
          &:focus {
            box-shadow: none !important;
          }
          @media (max-height: 559px) {
            width: 5.4vh;
            height: 5.4vh;
            font-size: 3.2vh;
          }
        }
        &.disabled {
          .page-link {
            opacity: 0.3;
          }
        }
        &.active {
          .page-link {
            background: #02a917;
          }
        }
        &:first-child {
          // left: 0;
          // position: absolute;
          .page-link {
            border-radius: 30px;
            font-size: 4.4vh;
            line-height: 4.4vh;
            width: 4.4vh;
            height: 4.4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008bd0;
            color: #fff;
            border: 0;
            @media (max-height: 559px) {
              font-size: 6vh;
              line-height: 6vh;
              width: 5.4vh;
              height: 5.4vh;
            }
          }
        }
        &:last-child {
          // right: 0;
          // position: absolute;
          .page-link {
            border-radius: 30px;
            font-size: 4.4vh;
            line-height: 4.4vh;
            width: 4.4vh;
            height: 4.4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008bd0;
            color: #fff;
            border: 0;
            @media (max-height: 559px) {
              font-size: 6vh;
              line-height: 6vh;
              width: 5.4vh;
              height: 5.4vh;
            }
          }
        }
      }
    }
  }
}
