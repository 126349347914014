:root {
  .categories-page {
    background-image: url(../../Assets/Images/background.png);
    padding: 0 !important;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    header {
      padding: 3vh 7vh 1.7vh;
      img {
        cursor: pointer;
        width: 10.2vh;
      }
    }
    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 73.6vh;
      text-align: center;
    }
    .content {
      height: calc(100vh - 26.4vh);
      padding: 0 4.6vh;
    }
    .categories-content {
      display: flex;
      .level-spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .spinner-border {
          --bs-spinner-width: 10vh;
          --bs-spinner-height: 10vh;
          border: 1vh solid #52bcc2;
          border-right-color: transparent;
        }
      }
      .level-boxes {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        height: fit-content;
        max-height: 73.4vh;
        overflow: auto;
        .level {
          background-image: url(../../Assets/Images/new-assets/box.png);
          flex: 0 0 21.6vh;
          margin: 2.2vh;
          background-size: cover;
          height: 21.6vh;
          padding: 3.3vh;
          text-align: center;
          min-width: 21.6vh;
          cursor: pointer;
          h1 {
            font-size: 14vh;
            text-shadow: 0 3px 3px #0000004d;
            color: var(--white);
            font-family: var(--Catamaran-700);
            line-height: 10.2vh;
          }
          .level-text {
            color: var(--white);
            text-shadow: 0 2px 2px #00000026;
            font-size: 2.5vh;
            line-height: 1;
          }
          .progress {
            --bs-progress-height: 0.9vh;
            --bs-progress-bar-bg: #0094da;
            margin-top: 1.3vh;
            .progress-bar {
              border-radius: 8px;
              &[aria-valuenow="100"] {
                background-color: #28f900;
              }
            }
          }
        }
      }
      .sidebar {
        background-image: url(../../Assets/Images/categories/categories-bg.png);
        min-width: 40vh;
        max-width: 40vh;
        padding: 7.5vh 5.2vh;
        background-size: contain;
        background-position-x: center;
        height: 75vh;
        text-align: center;
        z-index: 9;
        position: relative;
        .list-group {
          --bs-list-group-bg: transparent;
          --bs-list-group-border-width: 0;
          margin-top: 3.3vh;
          max-height: calc(100% - 90px);
          overflow: auto;
          .infinite-scroll-component {
            overflow: hidden !important;
          }
          .list-group-item {
            padding: 0.9vh 1.7vh;
            a {
              font-size: 3.3vh;
              color: var(--text-color);
              line-height: 1.2;
              &.active {
                font-family: var(--Catamaran-700);
              }
            }
          }
        }
        .title {
          h1 {
            color: var(--default-color);
            text-transform: uppercase;
            font-family: var(--Catamaran-700);
            font-size: 4.1vh;
          }
        }
      }
    }
    .footer {
      padding: 0 !important;
      border-top: 0;
      position: relative;
    }
    .pagination {
      position: absolute;
      align-items: center;
      top: 3.3vh;
      left: 7vh;
      li {
        &.muted {
          .page-link {
            background-color: transparent;
            font-size: 3.7vh;
            margin-top: -0.6vh;
            cursor: default;
          }
        }
        .page-link {
          border-radius: 50px;
          margin: 0.2vh;
          width: 4.4vh;
          height: 4.4vh;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          padding: 0;
          font-size: 2.2vh;
          &:focus {
            box-shadow: none !important;
          }
          @media (max-height: 559px) {
            width: 5.4vh;
            height: 5.4vh;
            font-size: 3.2vh;
          }
        }
        &.disabled {
          .page-link {
            opacity: 0.3;
          }
        }
        &.active {
          .page-link {
            background: #02a917;
          }
        }
        &:first-child {
          // left: 0;
          // position: absolute;
          .page-link {
            border-radius: 30px;
            font-size: 4.4vh;
            line-height: 4.4vh;
            width: 4.4vh;
            height: 4.4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008bd0;
            color: #fff;
            border: 0;
            @media (max-height: 559px) {
              font-size: 6vh;
              line-height: 6vh;
              width: 5.4vh;
              height: 5.4vh;
            }
          }
        }
        &:last-child {
          // right: 0;
          // position: absolute;
          .page-link {
            border-radius: 30px;
            font-size: 4.4vh;
            line-height: 4.4vh;
            width: 4.4vh;
            height: 4.4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008bd0;
            color: #fff;
            border: 0;
            @media (max-height: 559px) {
              font-size: 6vh;
              line-height: 6vh;
              width: 5.4vh;
              height: 5.4vh;
            }
          }
        }
      }
    }
  }
}
