:root {
  .profile-page {
    background-image: url(../../Assets/Images/background.png);
    padding: 0 !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .header {
      border-bottom: 0;
      padding: 1.7vh 7vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 3vh;
      .title {
        h2 {
          font-family: var(--Catamaran-700);
          font-size: 5.3vh;
          color: var(--default-color);
          margin-top: 1.7vh;
          text-transform: uppercase;
        }
      }
      img {
        cursor: pointer;
        width: 10.2vh;
        border-radius: 100%;
      }
    }
    .page-body {
      padding: 1.7vh 7vh;
      max-height: calc(100% - 26.4vh);
      min-height: calc(100% - 26.4vh);
      overflow-y: auto;
      position: relative;
      @media only screen and (max-height: 559px) {
        max-height: calc(100% - 29vh);
        min-height: calc(100% - 29vh);
      }
      .primary-bg-border {
        &.first-box {
          margin-bottom: 1.7vh;
        }
        .user-data {
          display: flex;
          justify-content: space-between;
          font-size: 3.2vh;
          color: var(--text-color);
        }
        .title-blue {
          color: var(--default-color);
          font-family: var(--Catamaran-700);
          font-size: 3.2vh;
          margin-bottom: 1.7vh;
        }
        form {
          text-align: start;
          .form-row {
            margin-bottom: 1.7vh;
            .col {
              width: 25%;
              padding: 0 1.3vh;
              position: relative;
              &:last-child {
                align-self: flex-end;
              }
            }
          }
          input {
            border: 4px solid var(--border-color);
            height: 6vh;
            border-radius: 8px;
            background-color: var(--blue);
            color: var(--white);
            font-family: var(--Catamaran-600);
            font-size: 2.4vh;
            line-height: 4vh;
            width: 100%;
            padding: 1.3vh;
            outline: none !important;
            @media only screen and (max-height: 559px) {
              border: 2px solid var(--border-color);
              border-radius: 6px;
            }
          }
          label {
            color: var(--text-color);
            text-transform: capitalize;
            // font-family: var(--Catamaran-600);
            text-shadow: 0px 3px 6px #00000029;
            margin-bottom: 0;
            font-size: 3.2vh;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            @media only screen and (max-height: 559px) {
              display: block;
            }
          }
          button {
            font-family: var(--Catamaran-700);
            font-size: 3.8vh;
            border-radius: 100px;
            background-image: url(../../Assets/Images/new-assets/qa-button.png);
            color: var(--default-color);
            width: 27vh;
            height: 9vh;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            margin-left: auto;
            background-size: contain;
            display: block;
            text-transform: uppercase;
            background-position: center;
            padding: 2vh 1.3vh 0.9vh 1.3vh;
          }
        }
        .validation-message {
          color: #ff1a1a;
          font-size: 2vh;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 2.6vh);
          position: absolute;
        }
      }
    }
    .footer {
      padding: 0 !important;
      border-top: 0;
    }
  }
}
