.leaderboard-table {
  table {
    width: 100%;
    thead {
      tr {
        background-color: var(--blue-light-bg);
        outline-color: var(--blue-border);
        outline-width: 4px;
        outline-style: solid;
        border-radius: 2vh;
        @media (max-height: 559px) {
          outline-width: 2px;
          border-radius: 1.4vh;
        }
        th {
          padding: 1.6vh 1.1vh 1.3vh 3.8vh;
          color: var(--default-color);
          font-size: 2.8vh;
          &:first-child {
            -moz-border-radius: 2vh 0 0 2vh;
            -webkit-border-radius: 2vh 0 0 2vh;
            border-radius: 2vh 0 0 2vh;
            text-align: center;
            padding: 1.6vh 1.1vh 1.3vh 1.6vh;
            @media (max-height: 559px) {
              -moz-border-radius: 1.5vh 0 0 1.5vh;
              -webkit-border-radius: 1.5vh 0 0 1.5vh;
              border-radius: 1.5vh 0 0 1.5vh;
              width: 50px;
            }
          }
          &:last-child {
            -moz-border-radius: 0 2vh 2vh 0;
            -webkit-border-radius: 0 2vh 2vh 0;
            border-radius: 0 2vh 2vh 0;
            text-align: center;
            @media (max-height: 559px) {
              -moz-border-radius: 0 1.5vh 1.5vh 0;
              -webkit-border-radius: 0 1.5vh 1.5vh 0;
              border-radius: 0 1.5vh 1.5vh 0;
            }
          }
        }
      }
    }
    tbody {
      td {
        padding: 1.6vh 1.1vh 1.3vh 3.8vh;
        font-size: 2.8vh;
        &:first-child {
          font-family: var(--Catamaran-600);
          text-align: center;
          padding: 1.6vh 1.1vh 1.3vh 1.6vh;
          img {
            max-height: 6.4vh;
          }
        }
        &:last-child {
          font-family: var(--Catamaran-600);
          text-align: center;
          img {
            max-height: 6vh;
          }
        }
      }
    }
  }
}
