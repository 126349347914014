:root {
  .forgot-page {
    .mvh-100 {
      min-height: 100vh;
    }
    .bg-signup {
      background-image: url(../../../Assets/Images/Auth/fogot-box.svg);
      background-size: contain;
      height: 70.1vh;
      width: 46vh;
      padding: 4.2vh 4vh 5.2vh 4vh;
      background-repeat: no-repeat;
    }
    .left-girl {
      margin-left: -12vh;
    }
    .authbox-content {
      padding-top: 5.2vh;
      margin-top: 5.2vh;
    }
    .forgot-link {
      padding-top: 6rem;
      color: var(--white);
      font-size: 22px;
    }
    .auth-page .auth-form-box h2 {
      margin-bottom: 0px;
    }
    .bottom-link {
      a {
        margin-top: 10vh;
        @media only screen and (max-height: 700px) {
          margin-top: 10vh;
        }
      }
    }
    .box-header {
      .fs-20 {
        font-size: 2.1vh;
        margin: 1.1vh 0;
      }
    }
    // .authform {
    //   margin: auto 0;
    //   display: flex;
    //   align-items: center;
    // }
  }
}
