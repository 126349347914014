:root {
  .login-page {
    .mvh-100 {
      min-height: 100vh;
    }
    .authbox-content {
      padding-top: 5vh;
    }
    .bg-signup {
      background-image: url(../../../Assets/Images/Auth/login-box.svg);
      background-size: contain;
      height: 70.1vh;
      width: 46vh;
      padding: 4.2vh 5.2vh 5.2vh 5.2vh;
      background-repeat: no-repeat;
    }
    .left-girl {
      margin-left: -12vh;
    }
    .forgot-link {
      margin-top: 8vh;
      color: var(--white);
      font-size: 2.5vh;
    }
    .bottom-link {
      a {
        margin-top: 10vh;
        @media only screen and (max-height: 700px) {
          margin-top: 10vh;
        }
      }
    }
  }
}
