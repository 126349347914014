:root {
  .register-page {
    .mvh-100 {
      min-height: 100vh;
    }
    .bg-signup {
      background-image: url(../../../Assets/Images/Auth/signup-box.svg);
      background-size: contain;
      // height: 800px;
      // width: 405px;
      height: 88vh;
      width: 44.5vh;
      padding: 5vh;
      background-repeat: no-repeat;
    }
    .left-girl {
      margin-left: -12vh;
    }
    .form {
      flex-direction: column;
    }
  }
}
