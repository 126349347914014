:root {
  .result-page {
    header {
      padding: 3vh 7vh 1.7vh;
      .header-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        img {
          width: 10.2vh;
          border-radius: 100%;
          cursor: pointer;
        }
        .page-title {
          width: 100%;
          text-align: center;
          padding-right: 10.2vh;
          h2 {
            font-family: var(--Catamaran-700);
            font-size: 5vh;
          }
        }
      }
    }
    .content {
      height: 62vh;
      .result-content {
        .trophy {
          img {
            height: 23vh;
          }
        }
        h2 {
          font-size: 4.4vh;
          font-family: var(--Catamaran-700);
          margin-top: 2.2vh;
        }
        h1 {
          font-size: 8vh;
          color: #36b449;
          font-family: var(--Catamaran-700);
          margin-bottom: 1.7vh;
        }
        h3 {
          font-size: 3.5vh;
          font-family: var(--Catamaran-700);
        }
        p {
          font-size: 2.8vh;
          margin-bottom: 4.8vh;
          margin-top: 1.1vh;
        }
      }
    }
    footer {
      .actions-button {
        margin-bottom: 1.6vh;
        margin-top: 0;
        z-index: 9;
        position: relative;
        display: flex;
        justify-content: center;
        .solid {
          font-family: var(--Catamaran-700);
          font-size: 2.9vh;
          background-image: url(../../Assets/Images/new-assets/qa-button.png);
          color: var(--default-color);
          width: 31vh;
          height: 10.4vh;
          background-color: transparent;
          border: none;
          background-size: contain;
          display: block;
          text-transform: uppercase;
          border-radius: 100px;
          background-repeat: no-repeat;
          background-position: center;
          padding: 2vh 1.3vh 0.9vh 1.3vh;
        }
        .outline {
          font-family: var(--Catamaran-700);
          font-size: 2.9vh;
          border: 0.5vh solid var(--default-color);
          color: var(--default-color);
          width: 31vh;
          height: 10.3vh;
          background-color: transparent;
          background-size: contain;
          display: block;
          text-transform: uppercase;
          border-radius: 78px;
          margin-right: 1.6vh;
          padding: 2vh 1.3vh 0.9vh 1.3vh;
        }
      }
    }
  }
}
