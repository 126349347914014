:root {
  /*---- Color Set ----*/
  --default-color: #304594;
  --text-color: #3b3b3b;
  --text-secondary: #2e2e2e;
  --black: #000000;
  --orange: #ff8700;
  --white: #fff;
  --transparent: transparent;
  --pink: #777e90;
  --green: #05ce78;
  --gray: #777e90;
  --darkgray: #596c7f;
  --lightgray: #bdc6d7;
  --veryLight: #edeef1;
  --blue: #0952a4;
  --blue-bar: #003cef;

  --border-color: #b1c1d0;
  --text-shadow: #aebccc;
  --blue-border: #32bbff;
  --blue-light-bg: #b4ebff;
  --progress-bar: #0094da;

  /* Font Families */
  --Catamaran-100: "Catamaran-Thin";
  --Catamaran-200: "Catamaran-ExtraLight";
  --Catamaran-300: "Catamaran-Light";
  --Catamaran-400: "Catamaran-Regular";
  --Catamaran-500: "Catamaran-Medium";
  --Catamaran-600: "Catamaran-SemiBold";
  --Catamaran-700: "Catamaran-Bold";
  --Catamaran-800: "Catamaran-ExtraBold";
  --Catamaran-900: "Catamaran-Black";

  /*----Typography ---*/
  --default-font-size: 2.4vh;
  --fs-70: 70px;
  --fs-60: 60px;
  --fs-48: 5.3vh;
  --fs-42: 42px;
  --fs-38: 38px;
  --fs-36: 4vh;
  --fs-32: 32px;
  --fs-30: 3.3vh;
  --fs-24: 24px;
  --fs-22: 22px;
  --fs-20: 20px;
  --fs-18: 18px;
  --fs-17: 17px;
  --fs-16: 16px;
  --fs-14: 14px;
  --fs-12: 12px;
  --fs-10: 10px;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-medium: 500;
  --fw-noraml: 400;
  --fw-light: 300;
  --smooth: all 0.3s ease 0s;

  /*---- Common Variable ----*/
  --p-0: 0;
  --m-0: 0;
  --m-15: 15px;
  --p-15: 15px;
}

/*---- Global styling ----*/
*,
body {
  box-sizing: border-box;
  scrollbar-color: #2b6ebd80 transparent;
}

body {
  padding: var(--p-0);
  margin: var(--m-0);
  font-size: var(--default-font-size);
  color: var(--text-color);
  font-family: var(--Catamaran-400);
}

html,
#root,
body {
  height: 100%;
}

.main-wrapper {
  min-height: 100%;
}

.body-wrapper {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

p {
  margin: 10px 0;
}

a {
  text-decoration: none;
  transition: var(--smooth);
  display: inline-block;
  color: var(--default-color);
  font-family: var(--Catamaran-400);
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none;
  color: var(--white);
  outline: none !important;
}

ul,
ol {
  padding: var(--p-0);
  margin: var(--m-0);
}

li {
  list-style: none;
  display: inline-block;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1230px;
  }
}

/*---- Genric classess ----*/

/*---- Typography ----*/
.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: var(--m-0);
}

h1,
.heading-1 {
  font-size: var(--fs-48);
}

h2,
.heading-2 {
  font-size: var(--fs-42);
  font-family: var(--Catamaran-600);
}

h3,
.heading-3 {
  font-size: var(--fs-36);
}

h4,
.heading-4 {
  font-size: var(--fs-32);
}

h5,
.heading-5 {
  font-size: var(--fs-24);
}

h6,
.heading-6 {
  font-size: var(--fs-20);
}

.fs-70 {
  font-size: var(--fs-70);
}

.fs-60 {
  font-size: var(--fs-60);
}

.fs-38 {
  font-size: var(--fs-38);
}

.fs-36 {
  font-size: var(--fs-36);
}

.fs-30 {
  font-size: var(--fs-30);
}

.fs-24 {
  font-size: var(--fs-24);
}

.fs-22 {
  font-size: var(--fs-22);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-17 {
  font-size: var(--fs-17);
}

.fs-16 {
  font-size: var(--fs-16);
}

.fs-14 {
  font-size: var(--fs-14);
}

.bold {
  font-weight: var(--fw-bold);
}

.semibold {
  font-weight: var(--fw-semibold);
}

.medium {
  font-weight: var(--fw-medium);
}

.normal {
  font-weight: var(--fw-noraml);
}

.light {
  font-weight: var(--fw-light);
}

/*--- Coloring ----*/
.dark-text {
  color: var(--default-color);
}

.dark-bg {
  background-color: var(--default-color);
}

.black-text {
  color: var(--black);
}

.black-bg {
  background-color: var(--black);
}

.white-text {
  color: var(--white);
}

.white-bg {
  background-color: var(--white);
}

.orange-text {
  color: var(--orange);
}

.orange-bg {
  background-color: var(--orange);
}

.pink-text {
  color: var(--pink);
}

.pink-bg {
  background-color: var(--pink);
}

.green-text {
  color: var(--green);
}

.green-bg {
  background-color: var(--green);
}

.gray-text {
  color: var(--gray);
}

.gray-bg {
  background-color: var(--gray);
}

.darkgray-text {
  color: var(--darkgray);
}

.darkgray-bg {
  background-color: var(--darkgray);
}

.lightgray-color {
  background-color: var(--lightgray);
}

.lightgray-bg {
  background-color: var(--lightgray);
}

.blue-color {
  background-color: var(--blue);
}

.blue-bg {
  background-color: var(--blue);
}

.veryLight-color {
  background-color: var(--veryLight);
}

.veryLight-bg {
  background-color: var(--veryLight);
}

.no-margin {
  margin: var(--m-0);
}

.no-padding {
  margin: var(--p-0);
}

.ellipis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
