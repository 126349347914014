:root {
  .answers-modal {
    padding: 4.4vh;
    .modal-fullscreen {
      width: 100%;
      .modal-content {
        background-color: rgb(255 255 255 / 50%);
        -webkit-backdrop-filter: blur(5.4vh) brightness(1.1);
        backdrop-filter: blur(5.4vh) brightness(1.1);
        border-radius: 6.5vh;
        padding: 2.2vh 3.3vh;
        .modal-header {
          border-bottom: none;
          .modal-title.h4 {
            width: 100%;
            padding-left: 8.5vh;
            h2 {
              font-size: 4vh;
              font-family: var(--Catamaran-700);
            }
          }
          .btn-close {
            background-image: url(../../Assets/Images/new-assets/Cross-sm.png);
            width: 7.6vh;
            height: 7.2vh;
            border-radius: 100%;
            background-size: contain;
            opacity: 1;
          }
        }
        .modal-body {
          padding: 1.7vh;
          .accordion {
            --bs-accordion-border-width: 0px;
            --bs-accordion-border-radius: 0;
            --bs-accordion-btn-color: var(--text-color);
            --bs-accordion-btn-bg: transparent;
            --bs-accordion-btn-icon: url(../../Assets/Images/icons/chevron-down.svg);
            --bs-accordion-btn-active-icon: url(../../Assets/Images/icons/chevron-down.svg);
            .accordion-item {
              border-radius: 2.2vh !important;
              margin-bottom: 1.1vh;
              background-color: #b4ebff;
              border: 0.3vh solid #32bbff;
              color: var(--text-color);
              .accordion-header {
                font-family: var(--Catamaran-400);
                .collapsed {
                  .question {
                    white-space: nowrap;
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
              .accordion-button {
                font-size: var(--fs-30);
                color: var(--text-color);
                align-items: flex-start;
                padding: 1.7vh 2.2vh;
                &::after {
                  margin-top: 1vh;
                  background-position-y: center;
                  width: 2.2vh;
                  height: 2.2vh;
                  background-size: contain;
                }
                span {
                  font-family: var(--Catamaran-700);
                  margin-right: 3.3vh;
                }
              }
              .accordion-button:not(.collapsed) {
                background-color: transparent;
                border: 0 !important;
                box-shadow: none !important;
                color: var(--text-color);
              }
              .accordion-button:focus {
                border: 0 !important;
                box-shadow: none !important;
              }
            }
            .accordion-body {
              font-size: var(--fs-30);
              padding: 1.7vh 2.2vh;
              .answers-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.7vh 0;
                .answers {
                  display: flex;
                  .options {
                    margin-right: 3.3vh;
                    img {
                      max-width: 5.5vh;
                      margin-right: 1.1vh;
                    }
                    span {
                      font-size: 3vh;
                    }
                  }
                }
                .points {
                  font-family: var(--Catamaran-700);
                  font-size: 3vh;
                }
              }
              .correct-answer {
                font-size: 2.8vh;
                color: #344ea2;
              }
            }
          }
        }
      }
    }
  }
}
