:root {
  .verification-modal {
    backdrop-filter: blur(6px);
    .modal-lg {
      @media (min-width: 992px) {
        // --bs-modal-width: 952px;
        --bs-modal-width: 103vh;
      }
      .modal-content {
        width: 100%;
        height: 82vh;
        background-image: url(../../Assets/Images/Auth/verification-modal/modal-bg.png);
        background-color: transparent;
        border: 0;
        background-size: cover;
        .modal-header {
          align-items: center;
          justify-content: center;
          border-bottom: 0;
          padding: 1.8vh;
          .modal-title {
            font-family: var(--Catamaran-700);
            font-size: 5.3vh;
            color: var(--default-color);
            margin-top: 14vh;
            max-width: 48%;
            line-height: 5.5vh;
          }
          .btn-close {
            position: absolute;
            right: 1vh;
            top: 7.3vh;
            opacity: 1;
            background-image: url(../../Assets/Images/new-assets/Cross-sm.png);
            width: 7vh;
            height: 7vh;
            background-size: contain;
            border-radius: 100%;
          }
        }
        .modal-body {
          padding: 1.8vh;
          .divider-dual {
            width: 100%;
            height: 5px;
            background-color: #fff;
            border-bottom: 2px solid #00ccff;
            border-radius: 10px;
            width: calc(100% - 19vh);
            margin: auto;
          }
          .modal-desc {
            font-size: 3.4vh;
            text-align: center;
            margin: 6.5vh 3.3vh 11vh 3.3vh;
          }
          .modal-btn {
            font-family: var(--Catamaran-700);
            font-size: 3.7vh;
            background-image: url(../../Assets/Images/new-assets/qa-button.png);
            color: var(--default-color);
            width: 29.5vh;
            height: 10vh;
            background-color: transparent;
            border: none;
            margin: auto;
            background-size: contain;
            display: block;
            text-transform: uppercase;
            border-radius: 100px;
            background-repeat: no-repeat;
          }
          .request-link {
            font-size: 2.5vh;
            text-decoration: underline;
            text-align: center;
            cursor: pointer;
            margin-top: 1.8vh;
          }
        }
      }
    }
  }
  // body.modal-open > :not(.modal) {
  //   -webkit-filter: blur(8px);
  //   -moz-filter: blur(8px);
  //   -o-filter: blur(8px);
  //   -ms-filter: blur(8px);
  //   filter: blur(8px);
  // }
}
