:root {
  .bottom-footer {
    background-image: url(../../Assets/Images/Home/footer-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3vh 7vh;
    width: 100%;
    margin: 0;
    img {
      width: 34.3vh;
    }
  }
}
