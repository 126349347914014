:root {
  .information-page {
    padding: 0 !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .header {
      border-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3vh 7vh 1.7vh;
      .title {
        width: 100%;
        text-align: center;
        padding-left: 88px;
        h2 {
          font-family: var(--Catamaran-700);
          font-size: 5.3vh;
          color: var(--default-color);
          margin-top: 0;
          text-transform: uppercase;
        }
      }
      img {
        width: 10.2vh;
        cursor: pointer;
      }
    }
    .page-content {
      padding: 1.7vh 7vh;
      height: calc(100% - 26vh);
      overflow: hidden;
      position: relative;
      .tab-content {
        font-size: 3.3vh;
        height: calc(100vh - 41vh);
        overflow-y: scroll;
        padding: 0 1.3vh;
      }
      .carousel-indicators {
        bottom: -6.5vh;
        margin-bottom: 0;
      }
      .carousel-indicators [data-bs-target] {
        width: 1.9vh;
        height: 1.9vh;
        border-radius: 100%;
        margin-right: 1.1vh;
        margin-left: 1.1vh;
        background-color: var(--default-color);
      }
      .sliderbox {
        // background-image: url(../../Assets/Images/categories/slidebox.svg);
        // background-size: contain;
        background: #b4ebff;
        border-radius: 2.2vh;
        border: 0.4vh solid #32bbff;
        max-width: 100%;
        width: 60vh;
        height: 49vh;
        margin: auto;
        margin-bottom: 3.3vh;
        padding: 3vh;
      }
      .video-box {
        position: relative;
        border-radius: 16px;
        background-color: #000000;
        overflow: hidden;
        width: 53.7vh;
        max-width: 100%;
        height: 36.6vh;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          width: 53.7vh !important;
          height: 31.6vh !important;
        }
        .cover {
          height: 100%;
          object-fit: cover;
        }
        .playbutton {
          position: absolute;
          top: 33%;
          left: 38%;
          cursor: pointer;
          width: 11.6vh;
        }
      }
      .tab-text {
        font-size: 3.3vh;
        height: calc(100vh - 41vh);
        overflow-y: scroll;
      }
      .nav-tabs {
        background: #b4ebff;
        padding: 0.6vh 3.3vh;
        border-radius: 2.2vh;
        border: 0.4vh solid #32bbff;
        margin-bottom: 2.7vh;
        .nav-item {
          button {
            font-size: 3.3vh;
            color: var(--default-color);
            border: 0 !important;
            margin: 0 1.1vh;
            padding: 0.9vh 1.7vh;
            &.active {
              background-color: transparent;
              border-color: transparent;
              font-family: var(--Catamaran-600);
              position: relative;
              &::after {
                content: "";
                width: 4.4vh;
                height: 0.8vh;
                background-color: var(--default-color);
                position: absolute;
                bottom: 0;
                left: 32%;
                border-radius: 3.3vh;
              }
            }
          }
        }
      }
    }
    .footer {
      padding: 0 !important;
      border-top: 0;
    }
  }
}
