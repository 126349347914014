:root {
    .rotate-wrapper {
        background-image: url(../../Assets/Images/phone-bg.png);
        background-position: center;
        min-height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        text-align: center;
        color: #fff;
        font-size: 30px;
        z-index: 2000;
        position: relative;
        .animation {
            margin-top: -70px;
        }
    }
}