:root {
  .badges-modal {
    backdrop-filter: blur(6px);
    .modal-lg {
      @media (min-width: 992px) {
        --bs-modal-width: 952px;
      }
      .modal-content {
        width: 100%;
        height: 81.5vh;
        background-image: url(../../Assets/Images/badges-modal/badges-modal-bg.png);
        background-color: transparent;
        border: 0;
        background-size: 100% 100%;
        background-position: center;
        .modal-header {
          height: 20vh;
          align-items: center;
          justify-content: center;
          border-bottom: 0;
          .modal-title {
            font-family: var(--Catamaran-700);
            font-size: 5.3vh;
            color: var(--default-color);
            margin-top: 2.5vh;
            text-transform: uppercase;
          }
          .btn-close {
            position: absolute;
            right: 1.7vh;
            top: 8.2vh;
            opacity: 1;
            background-image: url(../../Assets/Images/new-assets/Cross-sm.png);
            width: 6.9vh;
            height: 6.9vh;
            background-size: contain;
            border-radius: 100%;
          }
        }
        .modal-body {
          padding: 1.7vh;
          .main-badge {
            text-align: center;
            .badge-name {
              color: var(--text-secondary);
              font-family: var(--Catamaran-600);
              font-size: 3.4vh;
              line-height: 4vh;
              margin-top: -1vh;
            }
            img {
              margin-top: 1.3vh;
              margin-bottom: 1.7vh;
              height: 16.7vh;
            }
            .current-score {
              max-width: 16vh;
              margin: auto;
              .progress {
                --bs-progress-height: 1vh;
                --bs-progress-bg: #ffffffb3;
                .progress-bar {
                  border-radius: 10px;
                  background-color: #0094da;
                }
              }
              .score {
                color: var(--text-secondary);
                font-family: var(--Catamaran-600);
                font-size: 2.7vh;
                margin-top: 0.7vh;
                margin-bottom: 1.1vh;
              }
            }
          }
          .badges-grid {
            display: flex;
            justify-content: space-between;
            text-align: center;
            width: 100%;
            padding: 0 7.8vh;
            padding-top: 1.7vh;
            img {
              margin-bottom: 0.7vh;
              height: 12vh;
              object-fit: contain;
            }
          }
          .divider-dual {
            width: 100%;
            height: 0.5vh;
            background-color: #fff;
            border-bottom: 0.3vh solid #00ccff;
            border-radius: 10px;
            width: calc(100% - 20vh);
            margin: auto;
          }
        }
      }
    }
  }
  // body.modal-open > :not(.modal) {
  //   -webkit-filter: blur(8px);
  //   -moz-filter: blur(8px);
  //   -o-filter: blur(8px);
  //   -ms-filter: blur(8px);
  //   filter: blur(8px);
  // }
}
