.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.loader.show {
  opacity: 1;
  visibility: visible;
}
.loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 255, 255, 0.6);
  // backdrop-filter: blur(6px);
}
#darkMode .loader:before {
  background-color: rgba(0, 0, 0, 0.6);
}
.loader-content {
  position: relative;
  z-index: 1;
  text-align: center;
  > img {
    max-width: 90vh;
  }
}
.loader-content p {
  font-size: 3.6vh;
  margin: 0;
}
.loader-bottom {
  margin-top: 12%;
}
.spinner-border {
  --bs-spinner-width: 5.5vh;
  --bs-spinner-height: 5.5vh;
  border: 0.8vh solid #52bcc2;
  border-right-color: transparent;
}
